import React from 'react';

export const MetaIV = ({ className }) => {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 912 208'
      xmlSpace='preserve'
      className={className}
    >
      <g>
        <polygon points='672,72 672,136 696,136 696,192 616,192 586,136 608,136 608,72 584,72 584,16 696,16 696,72 	' />
        <polygon points='896,16 896,72 872,72 832,192 768,192 728,72 704,72 704,16 768,16 800,128 832,16 	' />
      </g>
      <path
        d='M512,16H16v176h592L512,16z M208,160h-32V96l-48,64L80,96v64H48V48h40l40,56l40-56h40V160z M320,80h-64v12h48v24h-48v12h64
	v32h-96V48h96V80z M392,160h-32V80h-32V48h96v32h-32V160z M504,160l-28-48l-28,48h-40l68-112l68,112H504z'
      />
    </svg>
  );
};
