import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Color from 'color';
import { BeugThemeContext } from '../../context/ThemeContext';

const AdminButtonStyled = styled(Link)`
  align-self: flex-start;
  cursor: pointer;
  width: 100%;
  padding: 1.4rem 2rem;
  color: ${props => props.colour};
  background: ${props => props.background.default};
  font-size: 1.6rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
  text-decoration: none;
  &:hover {
    background: ${props => props.background.hover};
  }
  &:active {
    background: ${props => props.background.active};
  }
`;

export const AdminButton = () => {
  const { colours } = useContext(BeugThemeContext);

  let red = Color(colours.red);

  return (
    <AdminButtonStyled
      to='/admin'
      colour={colours.white}
      background={{
        default: colours.red,
        hover: red.lighten(0.1).string(),
        active: red.darken(0.15).string()
      }}
    >
      Admin
    </AdminButtonStyled>
  );
};
