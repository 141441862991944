import React, { createContext, useState, useEffect } from 'react';
import { firestore } from '../firebase';

export const BeugThemeContext = createContext({});

export const BeugThemeProvider = ({ children }) => {
  const [state, setState] = useState({
    colours: {
      black: '#16161D',
      truBlack: '#000000',
      white: 'whitesmoke',
      tint: '#c291ec',
      red: '#e14646',
      green: '#77dd77'
    },
    tints: ['#c291ec', '#ACE7FF', '#FFF5BA', '#FFABAB', '#BFFCC6'],
    typography: {
      fontFamilies: [
        'benton-sans',
        'Helvetica Neue',
        'Helvetica',
        'Arial',
        'sans-serif'
      ]
    },
    breakpoints: {
      sm: '480px',
      md: '960px'
    }
  });

  const changeColour = async (colour, value) => {
    await firestore.doc('settings/theme').update({ [colour]: value });
    setState(s => ({ ...s, colours: { ...s.colours, [colour]: value } }));
  };

  useEffect(() => {
    firestore
      .doc('settings/theme')
      .get()
      .then(res =>
        setState(s => ({
          ...s,
          colours: { ...s.colours, tint: res.data().tint }
        }))
      );
  }, []);

  return (
    <BeugThemeContext.Provider value={{ ...state, changeColour }}>
      {children}
    </BeugThemeContext.Provider>
  );
};
