import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Button, useMediaQuery } from '@material-ui/core';

import { BeugThemeContext } from '../../context/ThemeContext';
import { Controls } from './Controls';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExternalLink,
  faChevronDown
} from '@fortawesome/pro-light-svg-icons';

const InfoBoxStyled = styled.div`
    cursor: pointer;
    ${props => props.mobileStyles}
    color: ${props => props.colour};
    z-index: 1;
  `;

const InfoBoxText = styled.div`
  border: 1px solid;
  border-bottom: ${props => (props.expanded ? 'none' : '1px solid')};
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 0.8rem 2rem;
  padding: ${props =>
    !props.expanded
      ? '2rem'
      : props.isMobile
      ? '4rem 4rem 1rem'
      : '4rem 4rem 2rem'};
  background: ${props => props.background};
  margin-bottom: -1px;
`;

const InfoTitle = styled.h1`
  font-weight: 800;
  font-style: italic;
  font-size: 2.4rem;
`;

const InfoSubtitle = styled.h2`
  font-size: 1.8rem;
  font-weight: 400;
`;

const ExtLink = styled(Button)`
  a {
    text-decoration: none;
  }
`;

const CollapseIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 2rem;
  right: 2rem;
`;

export const InfoBox = ({ artwork, colour }) => {
  const { colours, breakpoints } = useContext(BeugThemeContext);
  const isMobile = !useMediaQuery(`(min-width: ${breakpoints.md})`);
  const [expanded, setExpanded] = useState(true);

  const currency = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 2
  });

  return (
    <InfoBoxStyled
      colour={colour}
      mobileStyles={
        !isMobile
          ? ` position: absolute; bottom: 4rem; right: 4rem;`
          : ` position: fixed; bottom: 0; width: 100%;`
      }
    >
      <InfoBoxText
        onClick={() => setExpanded(!expanded)}
        background={colours.truBlack}
        expanded={expanded}
        isMobile={isMobile}
      >
        <InfoTitle>{artwork.title}</InfoTitle>
        <InfoSubtitle>{artwork.year}</InfoSubtitle>
        {expanded && (
          <>
            {!artwork.digital && artwork.price !== (null || undefined) && (
              <div style={{ fontSize: '2rem', fontWeight: '400' }}>
                {artwork.price === 'enquire' ? (
                  <ExtLink
                    color='secondary'
                    variant='outlined'
                    onClick={() => setExpanded(true)}
                  >
                    <a
                      href={`mailto:beug.rip@gmail.com?subject=Enquiry for ${artwork.title}`}
                    >
                      Enquire
                    </a>
                  </ExtLink>
                ) : artwork.price ? (
                  currency.format(parseFloat(artwork.price).toFixed(2))
                ) : (
                  'SOLD'
                )}
              </div>
            )}
            <div
              style={{
                fontFamily: 'Courier, monospaced'
              }}
            >
              {artwork.digital
                ? 'Digital'
                : `${artwork.dimensions.width}x${artwork.dimensions.height}mm`}
            </div>
            {(artwork.etsy || artwork.societySix) && (
              <div style={{ marginTop: '1rem' }}>
                {artwork.etsy && (
                  <ExtLink
                    onClick={() => setExpanded(true)}
                    color='secondary'
                    variant='outlined'
                  >
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href={artwork.etsy}
                    >
                      Etsy
                      <FontAwesomeIcon
                        style={{ marginLeft: '0.7rem' }}
                        icon={faExternalLink}
                      />
                    </a>
                  </ExtLink>
                )}
                {artwork.societySix && (
                  <ExtLink
                    onClick={() => setExpanded(true)}
                    color='secondary'
                    variant='outlined'
                  >
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href={artwork.societySix}
                    >
                      Society6
                      <FontAwesomeIcon
                        style={{ marginLeft: '0.7rem' }}
                        icon={faExternalLink}
                      />
                    </a>
                  </ExtLink>
                )}
              </div>
            )}
          </>
        )}
      </InfoBoxText>
      {expanded && <Controls />}
      {expanded && isMobile && (
        <CollapseIcon
          onClick={() => setExpanded(!expanded)}
          icon={faChevronDown}
        />
      )}
    </InfoBoxStyled>
  );
};
