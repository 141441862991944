import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { useAuthState } from 'react-firebase-hooks/auth';

import { auth } from '../firebase';

import { ArtProvider } from '../context/ArtContext';

import { Sidebar } from '../components/sidebar/Sidebar';
import { AdminSidebar } from '../components/admin/AdminSidebar';

import { AdminContent } from '../components/admin/AdminContent';

import { ListOfArtworks } from '../components/admin/ListOfArtworks/ListOfArtworks';
import { UploadArtwork } from '../components/admin/Upload/UploadArtwork';

const MainBox = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
`;

export const Admin = () => {
  const [user] = useAuthState(auth);
  return user ? (
    <ArtProvider>
      <MainBox>
        <Sidebar>
          <AdminSidebar />
        </Sidebar>
        <AdminContent>
          <Switch>
            <Route exact path='/admin' component={ListOfArtworks} />
            <Route path='/admin/create' component={UploadArtwork} />
          </Switch>
        </AdminContent>
      </MainBox>
    </ArtProvider>
  ) : (
    <Redirect to='/login' />
  );
};
