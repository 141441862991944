import React, { useContext } from 'react';
import styled from 'styled-components';
import Color from 'color';
import { Link } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../firebase';
import { BeugThemeContext } from '../../context/ThemeContext';

const Style = `
  cursor: pointer;
  width: 100%;
  padding: 1.4rem 2rem;
  font-size: 1.6rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
  text-decoration: none;
`;

const LoginButtonStyled = styled.button`
    ${Style}
    color: ${props => props.colour.default};
  &:hover {
    color: ${props => props.colour.hover};
    background: ${props => props.background.hover};
  }
  &:active {
    background: ${props => props.background.active};
  }
  `;
const LinkStyled = styled(Link)`
  ${Style}
    color: ${props => props.colour.default};
  &:hover {
    color: ${props => props.colour.hover};
    background: ${props => props.background.hover};
  }
  &:active {
    background: ${props => props.background.active};
  }
`;

export const SignInOutButton = () => {
  const [user] = useAuthState(auth);
  const { colours } = useContext(BeugThemeContext);

  let white = Color(colours.white);
  let black = Color(colours.black);

  return user ? (
    <LoginButtonStyled
      onClick={() => auth.signOut()}
      colour={{ default: white.fade(0.7).toString(), hover: colours.red }}
      background={{
        active: black.darken(0.65).toString(),
        hover: black.darken(0.3).toString()
      }}
    >
      Logout
    </LoginButtonStyled>
  ) : (
    <LinkStyled
      to='/login'
      colour={{ default: white.fade(0.7).toString(), hover: colours.red }}
      background={{
        active: black.darken(0.65).toString(),
        hover: black.darken(0.3).toString()
      }}
    >
      Login
    </LinkStyled>
  );
};
