import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import Color from 'color';

import { IconButton, useMediaQuery } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faBars } from '@fortawesome/pro-light-svg-icons';

import { BeugThemeContext } from '../../context/ThemeContext';

const ToggleButton = styled.div`
  display: ${props => (props.isMobile ? 'none' : 'flex')};
  position: fixed;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  width: 60px;
  height: 60px;
  align-items: center;
  justify-content: center;
  color: ${props => props.colour};
`;

const SidebarStyled = styled.div`
  position: absolute;
  display: flex;
  width: ${props => props.width};
  height: 100vh;
  flex-direction: column;
  text-align: center;
  background: ${props => props.background};
  overflow-y: scroll;
  z-index: 99;
  transition: width 300ms ease-in-out;
  &::-webkit-scrollbar {
    width: 0px;
    background-color: ${props => props.scrollBar};
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: black;
  opacity: 70%;
  z-index: 70;
`;

export const Sidebar = ({ children }) => {
  const { colours, breakpoints } = useContext(BeugThemeContext);
  let black = Color(colours.black);
  let tint = Color(colours.tint);

  const isMobile = !useMediaQuery(`(min-width:${breakpoints.md})`);

  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <ToggleButton isMobile={!isMobile} colour={colours.tint}>
        <IconButton onClick={toggleOpen}>
          <FontAwesomeIcon
            icon={open ? faTimes : faBars}
            color={colours.tint}
          />
        </IconButton>
      </ToggleButton>
      <SidebarStyled
        width={open || !isMobile ? 'auto' : '0px'}
        background={black.darken(0.2).toString()}
        scrollBar={tint.fade(0.5).toString()}
      >
        {children}
      </SidebarStyled>
      {open && <Overlay onClick={toggleOpen} />}
    </>
  );
};
