import React from 'react';
import { Route } from 'react-router-dom';
// import { useAuthState } from 'react-firebase-hooks/auth';
// import { auth } from './firebase';

import { Login } from './pages/Login';
import { Main } from './pages/Main';
import { Admin } from './pages/Admin';
// import { Splash } from './pages/Splash';

export const Routes = () => {
  // const [user] = useAuthState(auth);
  return (
    <>
      <Route exact path='/' component={Main} />
      <Route path='/login' component={Login} />
      <Route path='/admin' component={Admin} />
    </>
  );
};
