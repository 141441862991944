import React, { useState, createContext, useCallback, useEffect } from 'react';
import { firestore } from '../firebase';

export const ArtContext = createContext({});

export const ArtProvider = ({ children }) => {
  const [selectedArt, setSelectedArt] = useState(null);
  const [allArt, setAllArt] = useState([]);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const getAllArt = useCallback(async () => {
    let artArray = [];
    await firestore
      .collection('artwork')
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          artArray.push(doc.data());
        });
        setError(null);
      })
      .catch(err => setError(err.message));
    setLoading(false);
    setAllArt(artArray);
  }, []);

  useEffect(() => {
    const getArt = async () => {
      let artArray = [];
      await firestore
        .collection('artwork')
        .get()
        .then(snapshot => {
          snapshot.forEach(doc => {
            artArray.push(doc.data());
          });
          setError(null);
        })
        .catch(err => setError(err.message));
      setLoading(false);
      setAllArt(artArray);
      setSelectedArt(artArray[0]);
    };
    getArt();
    return () => {
      console.log('got em');
    };
  }, []);

  return (
    <ArtContext.Provider
      value={{
        art: selectedArt,
        selectArt: setSelectedArt,
        allArt,
        getAllArt,
        loading,
        error
      }}
    >
      {children}
    </ArtContext.Provider>
  );
};
