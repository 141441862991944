import React, { useState } from 'react';
import { Logo } from '../../../assets/Logo';
import { Navigation } from '../../navigation/Navigation';
import { ArtList } from './ArtList';
import { Bio } from './Bio';
import { AdminButton } from '../../footer/AdminButton';
import { SignInOutButton } from '../../footer/FooterButton';

import { useAuthState } from 'react-firebase-hooks/auth';

import { auth } from '../../../firebase';

export const MainSidebar = () => {
  const [user] = useAuthState(auth);
  const [expand, setExpanded] = useState(false);
  return (
    <>
      {user && <AdminButton />}
      <Logo height={'30px'} styles={`margin: 40px 0;`} />
      <ArtList expand={expand} setExpand={e => setExpanded(e)} />
      <Bio />
      <Navigation />
      <SignInOutButton />
    </>
  );
};
