import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';

import styled from 'styled-components';

import { TextField, CircularProgress } from '@material-ui/core';

import { auth } from '../firebase';

import { Button } from '../components/ui/forms/Button';
import { ErrorText } from '../components/ui/forms/ErrorText';
import { Logo } from '../assets/Logo';
import { BeugThemeContext } from '../context/ThemeContext';

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  overflow: visible;
  width: 100%;
  max-width: 280px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Login = () => {
  const { colours } = useContext(BeugThemeContext);
  const history = useHistory();
  const [user] = useAuthState(auth);
  const [login, setLogin] = useState({
    email: '',
    password: ''
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = e => {
    const { name, value } = e.target;
    setLogin(s => ({ ...s, [name]: value }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    auth
      .signInWithEmailAndPassword(login.email, login.password)
      .then(() => {
        setError(null);
        setLoading(true);
      })
      .catch(err => {
        setError(err.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (user) history.push('/admin');
    return;
  }, [user, history]);

  return (
    <LoginForm onSubmit={handleSubmit}>
      <Logo height={'40px'} styles={`margin-bottom: 20px;`} />
      {loading ? (
        <CircularProgress color='secondary' />
      ) : (
        <>
          <TextField
            name='email'
            label='Email'
            value={login.email}
            onChange={handleChange}
            type='email'
            color='secondary'
            margin='normal'
            variant='outlined'
            fullWidth
          />
          <TextField
            name='password'
            label='Password'
            value={login.password}
            onChange={handleChange}
            type='password'
            color='secondary'
            margin='normal'
            variant='outlined'
            fullWidth
          />
          <Button colour={colours.tint} margin='2rem 0 2rem 0'>
            Login
          </Button>
        </>
      )}
      {error && <ErrorText message={error} />}
    </LoginForm>
  );
};
