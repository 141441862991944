import React, { StrictMode } from 'react';

import { BrowserRouter as Router, Switch } from 'react-router-dom';

import { Routes } from './Routes';
import { BeugThemeProvider } from './context/ThemeContext';
import { MaterialThemeProvider } from './context/MaterialThemeProvider';
import { SettingsProvider } from './context/SettingsContext';
import { GlobalStyle } from './style/GlobalStyle';

function App() {
  return (
    <StrictMode>
      <Router>
        <SettingsProvider>
          <BeugThemeProvider>
            <MaterialThemeProvider>
              <GlobalStyle />
              <Switch>
                <Routes />
              </Switch>
            </MaterialThemeProvider>
          </BeugThemeProvider>
        </SettingsProvider>
      </Router>
    </StrictMode>
  );
}

export default App;
