import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import MDSpinner from 'react-md-spinner';
import { firestore } from '../../../firebase';
import { ListItem } from './ListItem';
import { Filter } from './Filter';
import { BeugThemeContext } from '../../../context/ThemeContext';

const List = styled.ul`
  width: 100%;
`;

const Artworks = () => {
  const { colours } = useContext(BeugThemeContext);
  const [allArt, setAllArt] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState({
    field: 'title',
    direction: 'asc'
  });

  const changeOrderBy = (field, direction) => {
    setFilter({ field, direction });
  };

  useEffect(() => {
    const getAllArt = async () => {
      let artArray = [];
      setLoading(true);
      await firestore
        .collection('artwork')
        .orderBy(filter.field, filter.direction)
        .get()
        .then(snapshot => {
          snapshot.forEach(doc => {
            artArray.push({ id: doc.id, ...doc.data() });
          });
          setError(null);
        })
        .catch(err => setError(err.message));
      setAllArt(artArray);
      setLoading(false);
    };
    getAllArt();

    return () => {
      console.log('got em');
    };
  }, [filter.direction, filter.field]);

  return loading ? (
    <MDSpinner singleColor={colours.tint} style={{ margin: 'auto' }} />
  ) : (
    <>
      <Filter sortFunction={changeOrderBy} filter={filter} />
      <Grid container component={List} spacing={4}>
        {error && <div className='text-red'>{error}</div>}
        {allArt.map(artwork => (
          <ListItem key={artwork.id} artwork={artwork} />
        ))}
      </Grid>
    </>
  );
};

export const ListOfArtworks = () => {
  return (
    <>
      <Filter />
      <Artworks />
    </>
  );
};
