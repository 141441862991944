import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { CircularProgress, useMediaQuery } from '@material-ui/core';

import { ArtContext } from '../../context/ArtContext';
import { BeugThemeContext } from '../../context/ThemeContext';
import { InfoBox } from './InfoBox';

const ImageBox = styled.div`
  position: relative;
  display: ${props => (props.isMobile ? 'flex' : 'block')};
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
  height: ${props => (props.isMobile ? '100vh' : 'calc(100% - 80px)')};
  width: ${props => (props.isMobile ? '100%' : 'calc(100% - 80px)')};
`;

const MobileArtwork = styled.div`
  z-index: -1;
  height: 100%;
  background-image: url(${props => props.art && props.art.imageUrl});
  background-size: ${props => (props.zoomed ? 'cover' : 'contain')};
  background-position: top center;
  background-repeat: no-repeat;
  position: relative;
`;

const Zoom = styled.div`
  display: ${props => (props.isMobile ? 'block' : 'none')};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Artwork = () => {
  const { art } = useContext(ArtContext);
  const { colours, breakpoints } = useContext(BeugThemeContext);

  const isMobile = !useMediaQuery(`(min-width: ${breakpoints.md})`);

  const [loaded, setLoaded] = useState(false);
  const [zoomed, setZoomed] = useState(false);
  const [zoomCoords, setZoomCoords] = useState(null);

  const toggleZoom = () => {
    setZoomed(!zoomed);
  };

  const relativePosition = e => {
    e.persist();
    let x = e.nativeEvent.offsetX / 10;
    let y = e.nativeEvent.offsetY / 10;
    setZoomCoords({ x, y });
  };

  const DesktopArtwork = styled.div`
    cursor: ${zoomed ? 'zoom-out' : 'zoom-in'};
    height: 100%;
    background-image: url(${art && art.imageUrl});
    background-size: ${zoomed ? 'cover' : 'contain'};
    background-position: ${zoomCoords
      ? `${zoomCoords.x}% ${zoomCoords.y}%`
      : 'center'};
    background-repeat: no-repeat;
  `;

  return (
    <ImageBox isMobile={isMobile}>
      <Zoom onClick={toggleZoom} isMobile={isMobile}>
        <img
          src={art.imageUrl}
          alt={art.title}
          onLoad={() => setLoaded(true)}
          style={{ position: 'absolute', width: '100%', opacity: 0 }}
        />
      </Zoom>
      {!loaded ? (
        <div
          style={{
            width: '100%',
            height: isMobile ? '75%' : '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <CircularProgress color='secondary' />
        </div>
      ) : isMobile ? (
        <MobileArtwork
          alt={art && art.title}
          src={art && art.imageUrl}
          onClick={toggleZoom}
          zoomed={zoomed}
          art={art}
        />
      ) : (
        <DesktopArtwork
          onClick={() => {
            toggleZoom();
            if (zoomed) setZoomCoords(null);
          }}
          onMouseLeave={() => {
            setZoomCoords(null);
          }}
          onMouseMove={e => {
            if (zoomed) relativePosition(e);
          }}
          zoomed={zoomed}
          zoomCoords={zoomCoords}
          art={art}
        />
      )}
      <InfoBox colour={colours.tint} artwork={art} />
    </ImageBox>
  );
};
