import React, { useState } from 'react';

import {
  TextField,
  Grid,
  Button,
  Checkbox,
  FormControlLabel,
  FormControl,
  InputLabel,
  InputAdornment,
  OutlinedInput
} from '@material-ui/core';

import EditForm from './EditListItemForm';
import { firestore } from '../../../firebase';

export const EditListItem = ({ artwork, key, back }) => {
  const [state, setState] = useState({
    title: artwork.title,
    year: artwork.year,
    price: artwork.price,
    dimensions: artwork.dimensions && {
      width: artwork.dimensions.width,
      height: artwork.dimensions.height
    },
    digital: artwork.digital,
    etsy: artwork.etsy,
    societySix: artwork.societySix
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setState(s => ({ ...s, [name]: value }));
  };

  const handleDigiCheck = e => {
    setState(s => ({ ...s, digital: !s.digital }));
  };

  const handleDimensionsChange = e => {
    const { name, value } = e.target;
    setState(s => ({
      ...s,
      dimensions: { ...s.dimensions, [name]: value }
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    await firestore
      .doc(`/artwork/${artwork.id}`)
      .update(state)
      .then(res => back())
      .catch(err => console.log(err.message));
  };

  return (
    <EditForm handleSubmit={handleSubmit}>
      <TextField
        name='title'
        value={state.title}
        onChange={handleChange}
        label='Title'
        color='secondary'
        margin='normal'
        variant='outlined'
        required
        fullWidth
      />

      <TextField
        name='year'
        value={state.year}
        onChange={handleChange}
        label='Year'
        color='secondary'
        margin='normal'
        variant='outlined'
        required
        fullWidth
      />
      {!state.digital && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              name='width'
              value={state.dimensions.width}
              onChange={handleDimensionsChange}
              label='Width'
              color='secondary'
              margin='normal'
              variant='outlined'
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name='height'
              value={state.dimensions.height}
              onChange={handleDimensionsChange}
              label='Height'
              color='secondary'
              margin='normal'
              variant='outlined'
              fullWidth
            />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            name='etsy'
            value={state.etsy}
            onChange={handleChange}
            label='Etsy'
            color='secondary'
            margin='normal'
            variant='outlined'
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name='societySix'
            value={state.societySix}
            onChange={handleChange}
            label='Society6'
            color='secondary'
            margin='normal'
            variant='outlined'
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl
            fullWidth
            margin='normal'
            variant='outlined'
            color='secondary'
          >
            <InputLabel htmlFor='price'>Price</InputLabel>
            <OutlinedInput
              id='price'
              name='price'
              value={state.price}
              onChange={handleChange}
              startAdornment={
                <InputAdornment position='start'>$</InputAdornment>
              }
              labelWidth={42}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.digital}
                value={state.digital}
                onChange={handleDigiCheck}
              />
            }
            label='Digital'
            style={{
              textTransform: 'uppercase',
              fontWeight: '700'
            }}
          />
        </Grid>
      </Grid>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginTop: '1rem'
        }}
      >
        <Button color='default' onClick={back}>
          Cancel
        </Button>
        <Button color='secondary' type='submit'>
          Save
        </Button>
      </div>
    </EditForm>
  );
};
