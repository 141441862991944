import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { BeugThemeContext } from '../context/ThemeContext';

const LogoStyled = styled(Link)`
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
  ${props => props.styles}
`;
const Svg = styled.svg``;

export const Logo = ({ styles, height }) => {
  const { colours } = useContext(BeugThemeContext);
  return (
    <LogoStyled to='/' styles={styles}>
      <Svg
        fill={colours.white}
        strokeWidth='0.05em'
        version='1.1'
        id='BEUGLOGO'
        xmlns='http://www.w3.org/2000/svg'
        x='0'
        y='0'
        height={height}
        viewBox='0 0 230 60'
        xmlSpace='preserve'
        stroke='none'
      >
        <path
          d='M1 57.9L12.5 2.1h28.8c11.4 0 16.4 3.7 16.4 12 0 7-3.4 11.4-10 13.2h-.4l-.1.5v.6l.4.1c4.7 1 8.2 5.6 8.2 10.9C55.8 51 47.2 58 32.9 58H1zm18.6-12.5h11.8c1.9 0 7.9-.4 7.9-6.2 0-4.7-3.7-5.4-7.3-5.4H22l-2.4 11.6zM24 24.2h10.5c3.3 0 6.8-1.3 6.8-5 0-4-3-4.5-5.9-4.5H26l-2 9.5z'
          strokeDasharray='312.258056640625'
        ></path>
        <path
          d='M41.3 2.6c11.2 0 16 3.4 16 11.5 0 6.7-3.2 10.9-9.6 12.7h-.9v2l.8.2c4.5 1 7.8 5.3 7.8 10.4 0 6.1-2.2 10.7-6.6 13.9-3.9 2.8-9.3 4.2-15.8 4.2H1.6L12.9 2.6h28.4M23.4 24.7h11.1c3.4 0 7.3-1.5 7.3-5.5 0-5-4.5-5-6.4-5h-9.8l-.2.8-1.8 8.6-.2 1.1M19 45.9H31.4c4.1 0 8.4-1.8 8.4-6.7 0-5.8-5.4-5.8-7.8-5.8H21.6l-.2.8-2.2 10.6-.2 1.1M41.3 1.6H12.1L.3 58.4H33c12.4 0 23.4-5.4 23.4-19.1 0-5.3-3.3-10.3-8.6-11.4v-.2h.1c6.8-1.8 10.4-6.5 10.4-13.7 0-8.1-4.7-12.4-17-12.4zM24.6 23.7l1.8-8.6h9c3.1 0 5.4.6 5.4 4 0 3.5-3.5 4.5-6.3 4.5h-9.9v.1zm-4.4 21.2l2.2-10.6H32c3.7 0 6.8.6 6.8 4.8 0 4.6-4.3 5.7-7.4 5.7H20.2v.1z'
          strokeDasharray='625.1978149414062'
        ></path>
        <path
          d='M57.2 57.9L68.8 2.1h45.9L112 15.7H82.6l-1.8 8.1h27.3l-2.6 12.5H78.2l-1.7 8.1h30l-2.8 13.5z'
          strokeDasharray='320.3873596191406'
        ></path>
        <path
          d='M114.1 2.6l-2.5 12.6H82.2l-.2.8-1.5 7-.3 1.2h27.3l-2.4 11.5H77.8l-.2.8-1.5 7.1-.3 1.2h30l-2.6 12.6H57.8L69.2 2.6h44.9m1.2-1H68.4L56.6 58.4h47.6l3-14.6H77.1l1.5-7.1H106l2.8-13.5H81.5l1.5-7.1h29.4l2.9-14.5z'
          strokeDasharray='640.9765014648438'
        ></path>

        <path
          d='M136.9 59.3c-17.4 0-23.6-8.7-23.6-16.8 0-.8.2-2.3.6-4.5l7.5-35.8h16.5l-6.3 30.4c-.4 1.8-.9 4.7-.9 5.6v.3c0 1.3-.1 4 1.8 5.8 1.2 1.2 3 1.8 5.4 1.8 7.5 0 9.9-6.7 11.2-13.2l6.4-30.8H172l-7.7 37c-2.8 13.2-12.3 20.2-27.4 20.2z'
          strokeDasharray='281.3676452636719'
        ></path>
        <path
          d='M171.3 2.6L163.8 39c-3.5 16.4-16.5 19.8-26.9 19.8-17 0-23.1-8.4-23.1-16.3 0-.8.2-2.2.6-4.4l7.4-35.4h15.5l-6.2 29.8c-.4 1.8-.9 4.7-.9 5.7v.3c0 1.4-.1 4.2 1.9 6.2 1.3 1.3 3.2 2 5.7 2 7.9 0 10.4-6.9 11.7-13.6l6.4-30.4h15.4m1.3-1.1h-17.5l-6.5 31.2c-1.3 6.3-3.5 12.8-10.7 12.8-7.1 0-6.6-5.7-6.6-7.5 0-.9.5-3.7.9-5.5l6.4-31H121l-7.6 36.2c-.2 1.3-.6 3.5-.6 4.6 0 9.1 7.1 17.3 24.1 17.3 14.3 0 24.8-6.3 27.8-20.6l7.9-37.5z'
          strokeDasharray='562.6508178710938'
        ></path>

        <path
          d='M193.1 59.3c-21.9 0-22.6-21-22.6-23.4 0-17.5 9.9-35.1 32-35.1 14.1 0 22.7 7.6 23 20.2h-15.6c-.6-4.7-3.4-7.1-8.5-7.1-10 0-14.4 10.3-14.4 20.5 0 7.6 3.6 11.6 10.4 11.6 5.5 0 9.2-2.3 10.9-6.6l.3-.7h-8.1l2.3-11.2H225l-6.3 30.3h-10.1l.5-5.2-.7-.7-.3.5c-4.3 5.7-10.5 6.9-15 6.9z'
          strokeDasharray='319.46185302734375'
        ></path>
        <path
          d='M202.5 1.3c13.6 0 22 7.2 22.5 19.2h-14.6c-.7-4.7-3.7-7.1-8.9-7.1-10.3 0-14.9 10.6-14.9 21 0 7.8 3.9 12.1 10.9 12.1 5.7 0 9.6-2.4 11.4-6.9l.5-1.4h-8.1l2.1-10.2h21.2l-6.1 29.3h-9.2l.4-4.4v-.5l-.3-.3-.2-.2-.8-.8-.7.9c-4 5.5-10.1 6.6-14.6 6.6-8.3 0-14.4-3-18.2-8.9-3.6-5.7-3.9-12.2-3.9-14.1 0-17 9.7-34.3 31.5-34.3m0-1C180.2.3 170 18 170 35.8c0 7.1 3.2 23.9 23.1 23.9 6 0 11.7-2.1 15.4-7l.2.2-.5 5.5h11.1l6.5-31.3h-23.2l-2.5 12.2h8c-1.8 4.6-5.7 6.3-10.4 6.3-7.1 0-9.9-4.5-9.9-11.1 0-9.8 4.3-20 13.9-20 4.5 0 7.6 1.8 8 7.1H226C225.8 6.7 215.2.3 202.5.3z'
          strokeDasharray='638.4700927734375'
        ></path>
      </Svg>
    </LogoStyled>
  );
};
