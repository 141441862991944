import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import Color from 'color';
import { CSSTransition } from 'react-transition-group';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';

import { SettingsContext } from '../../../context/SettingsContext';
import { BeugThemeContext } from '../../../context/ThemeContext';

const Container = styled.div`
  position: relative;
  text-align: left;
  width: calc(100% - 4rem);
  margin: auto auto 2rem;
`;

const Paragraph = styled.p`
  border: 1px solid ${props => props.colour.tint};
  background: ${props => props.colour.background};
  padding: 20px;
  white-space: pre-line;
  font-family: 'Courier New', Courier, monospace;
  font-size: 2rem;
  line-height: 2.4rem;
  max-width: 400px;
`;

const Toggle = styled.div`
  text-transform: uppercase;
  font-style: italic;
  font-weight: 800;
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.expand && '2rem'};
  cursor: pointer;
  .icon {
    margin-left: ${props => (props.expand ? '13px' : '10px')};
    margin-top: -2px;
    transition: transform ease-in-out 200ms;
    transform: ${props => props.expand && 'rotate(90deg)'};
  }
  &:hover {
    .icon {
      transform: translateX(3px) scale(1.2)
        ${props => props.expand && 'rotate(90deg)'};
    }
  }
`;

export const Bio = () => {
  const [expand, setExpand] = useState(false);
  const { bio } = useContext(SettingsContext);
  const { colours } = useContext(BeugThemeContext);

  const duration = 1000;

  return (
    <Container>
      <Toggle onClick={() => setExpand(!expand)} expand={expand}>
        About <FontAwesomeIcon icon={faChevronRight} className='icon' />
      </Toggle>
      {expand && (
        <CSSTransition
          in={expand}
          timeout={duration}
          classNames='bio'
          unmountOnExit
        >
          <Paragraph
            colour={{
              tint: colours.tint,
              background: Color(colours.tint)
                .fade(0.9)
                .string()
            }}
          >
            {bio}
          </Paragraph>
        </CSSTransition>
      )}
    </Container>
  );
};
