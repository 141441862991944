import React from 'react';

export const Volim = ({ className }) => {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 263 193'
      xmlSpace='preserve'
      className={className}
    >
      <path
        d='M206.51,128.51V43.97L131.32,0.56L56.13,43.97v85.13L1.4,188.93l3.85,3.51l54.5-59.57l71.58,41.32l71.94-41.55l54.5,59.57
	l3.83-3.51L206.51,128.51z M201.32,127.79l-69.99,40.4l-69.99-40.4V46.96l69.99-40.4l69.99,40.4V127.79z'
      />
    </svg>
  );
};
