import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/pro-solid-svg-icons';
import { BeugThemeContext } from '../../../context/ThemeContext';
import { Popup } from './Popup';
import { firestore } from '../../../firebase';
import { EditListItem } from './EditListItem';

const ListItemStyled = styled.li`
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  background: url(${props => props.image});
  background-position: center;
  background-size: cover;
  position: relative;
`;

const TitleBox = styled.div`
  display: flex;
  background: ${props => props.background};
  border: 1px solid ${props => props.colour};
  width: 100%;
  padding: 1.2rem 2rem 1rem 2rem;
  margin-top: 300px;
`;

const Title = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  font-style: italic;
  margin-right: 1rem;
`;

const Edit = styled.div`
  margin-left: auto;
  font-weight: 800;
  cursor: pointer;
  &:hover {
    color: ${props => props.colour};
  }
`;

const DeleteIcon = styled.div`
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 1rem 1.5rem;
  background: ${props => props.background};
  border: 2px solid ${props => props.colour};
  &:hover {
    color: ${props => props.colour};
  }
  &:active {
    background: ${props => props.colour};
    border-color: ${props => props.background};
    color: ${props => props.background};
  }
`;

const Subtitle = styled.h2`
  font-size: 2rem;
  font-weight: 400;
`;

export const ListItem = ({ artwork }) => {
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(null);

  const setPopup = value => {
    setOpen(value);
  };

  const handleDelete = id => {
    firestore
      .doc(`artwork/${id}`)
      .delete()
      .then(res => {
        console.log(res);
        setPopup(false);
      })
      .catch(err => setMessage(err.message));
  };

  const { colours } = useContext(BeugThemeContext);

  return (
    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
      <ListItemStyled image={artwork.imageUrl}>
        {edit ? (
          <EditListItem
            key={artwork.title}
            artwork={artwork}
            back={() => setEdit(false)}
          />
        ) : (
          <>
            <DeleteIcon
              onClick={() => setPopup(true)}
              colour={colours.red}
              background={colours.black}
            >
              <FontAwesomeIcon icon={faTrash} />
            </DeleteIcon>
            <TitleBox colour={colours.tint} background={colours.truBlack}>
              <Title>{artwork.title}</Title>
              <Subtitle>{artwork.year}</Subtitle>
              <Edit onClick={() => setEdit(true)} colour={colours.tint}>
                <FontAwesomeIcon
                  icon={faEdit}
                  style={{ marginRight: '1rem' }}
                />
                EDIT
              </Edit>
            </TitleBox>
          </>
        )}
      </ListItemStyled>
      <Popup
        title={`Delete ${artwork.title}?`}
        open={open}
        content={message}
        onClose={() => setPopup(false)}
        onSubmit={() => handleDelete(artwork.id)}
      />
    </Grid>
  );
};
