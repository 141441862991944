import React, { useContext } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from '@material-ui/core';

import { Sidebar } from '../components/sidebar/Sidebar';
import { Content } from '../components/Content';
import { ArtProvider } from '../context/ArtContext';
import { BeugThemeContext } from '../context/ThemeContext';
import { MainSidebar } from '../components/sidebar/MainSidebar/MainSidebar';

const MainBox = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
`;

export const Main = () => {
  const { breakpoints } = useContext(BeugThemeContext);
  const isMobile = !useMediaQuery(`(min-width: ${breakpoints.md})`);

  return (
    <ArtProvider>
      <MainBox isMobile={isMobile}>
        <Sidebar>
          <MainSidebar />
        </Sidebar>
        <Content />
      </MainBox>
    </ArtProvider>
  );
};
