import React, { useContext } from 'react';
import styled from 'styled-components';

import { BeugThemeContext } from '../../context/ThemeContext';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 0 3rem;
`;

const ChooseColour = styled.div`
  background: ${props => props.background};
  padding: 1rem 2rem 0;
  border: 1px solid;
  border-bottom: none;
  margin-top: 4rem;
  font-weight: 700;
  text-transform: uppercase;
`;

const PickerWrapper = styled.div`
  background: ${props => props.background};
  border: 1px solid;
  border-top: none;
`;

const Picker = styled.ul`
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem 2rem;
`;

const PickerItem = styled.li`
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  padding: 3px;
  background-clip: content-box;
`;

export const ColourPicker = () => {
  const { colours, tints, changeColour } = useContext(BeugThemeContext);
  return (
    <Wrapper>
      <ChooseColour background={colours.truBlack}>Colour</ChooseColour>

      <PickerWrapper background={colours.truBlack}>
        <Picker>
          {tints.map((col, index) => (
            <PickerItem
              key={index}
              style={{
                backgroundColor: col,
                border: col === colours.tint ? '1px solid' : 'none'
              }}
              onClick={() => changeColour('tint', col)}
            />
          ))}
        </Picker>
      </PickerWrapper>
    </Wrapper>
  );
};
