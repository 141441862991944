import React, { useContext } from 'react';
import Color from 'color';
import { createGlobalStyle } from 'styled-components';

import { BeugThemeContext } from '../context/ThemeContext';

export const GlobalStyle = () => {
  const { colours } = useContext(BeugThemeContext);

  let tint = Color(colours.tint);

  const GlobalStyles = createGlobalStyle`
  *::selection {
    background: ${colours.black};
    color: ${colours.tint};
  }

  body {
    font-size: 1.8rem;
    line-height: 2.6rem;
    background: ${colours.black};
    color: ${colours.white};
    font-family: benton-sans, 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 400;
    scrollbar-face-color: ${colours.tint};
    scrollbar-track-color: transparent;
  }

  #root {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-thumb {
    background: ${tint.fade(0.4).string()};
  }

  ::-webkit-scrollbar-track {
    box-shadow: none;
  }
`;

  return <GlobalStyles />;
};
