import React, { useContext } from 'react';
import styled from 'styled-components';
import { BeugThemeContext } from '../../../context/ThemeContext';

export const ErrorText = ({ message }) => {
  const { colours } = useContext(BeugThemeContext);

  const Error = styled.span`
    color: ${colours.red};
    font-family: monospace;
    font-size: 2rem;
  `;

  return <Error>{message}</Error>;
};
