import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import Color from 'color';
import MDSpinner from 'react-md-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { ArtContext } from '../../../context/ArtContext';
import { BeugThemeContext } from '../../../context/ThemeContext';

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
};
function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const ListWrapper = styled.div`
  width: 100%;
  padding: 0 2rem;
  margin-bottom: 2rem;
`;

const ArtListStyled = styled.ul`
  min-width: 240px;
  max-height: ${props =>
    props.expand
      ? 'auto'
      : props.height < 1200 && !props.expand
      ? '200px'
      : '600px'};
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${props => props.background.default};
  border: 1px solid ${props => props.colour};
  overflow-y: scroll;
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: ${props => props.background.transparent};
  }

  &::-webkit-scrollbar-thumb {
    cursor: ns-resize;
    background-color: ${props => props.colour};
    border: none;
    border-radius: 2px;
  }
`;

const ArtListItem = styled.li`
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 700;
  padding: 1.1rem 1rem 1rem 1rem;
  margin-top: -1px;
  background: ${props => props.background.default};
  &:hover {
    background: ${props => props.background.hover};
  }
  &:active {
    background: ${props => props.background.active};
  }
`;

const ExpandList = styled.h2`
  display: ${props =>
    props.showExpand && props.height < 1200 ? 'block' : 'none'};
  cursor: pointer;
  padding: 0.3rem 1rem;
  font-weight: 800;
  font-style: italic;
  color: $tint;
  text-transform: uppercase;
  text-align: center;
  border-radius: 0px 0px 5px 5px;
  background: ${props => props.background};
  border: 1px solid ${props => props.colour};
  border-top: none;
`;

// const ArtListTitle = styled.h2`
//   position: absolute;
//   top: -39px;
//   left: -1px;
//   padding: 1rem;
//   font-weight: 800;
//   font-style: italic;
//   color: $tint;
//   text-transform: uppercase;
//   border-radius: 5px 5px 0 0;
//   background: ${props => props.background};
//   border: 1px solid ${props => props.colour};
//   border-bottom: none;
//   z-index: 2;
// `;

export const ArtList = ({ expand, setExpand }) => {
  const { art, selectArt, allArt, loading, error } = useContext(ArtContext);
  const { colours } = useContext(BeugThemeContext);
  const { height } = useWindowDimensions();

  const [showExpand, setShowExpand] = useState(false);

  const tint = Color(colours.tint);

  useEffect(() => {
    height < 1200 && setShowExpand(true);
  }, [height]);

  return (
    <ListWrapper>
      <ArtListStyled
        expand={expand}
        height={height}
        background={{
          default: colours.truBlack,
          transparent: tint.fade(0.5).string()
        }}
        colour={colours.tint}
      >
        {/* <ArtListTitle background={tint.darken(0.85).toString()} colour={colours.tint}>
          Artwork
        </ArtListTitle> */}
        {error ? (
          <div className='text-red' style={{ padding: '1rem' }}>
            {error}
          </div>
        ) : loading ? (
          <div style={{ padding: '2rem', textAlign: 'center' }}>
            <MDSpinner singleColor={colours.tint} />
          </div>
        ) : null}
        {allArt.map((artwork, index) => (
          <ArtListItem
            key={index}
            onClick={() => selectArt(artwork)}
            style={{
              color: art && art.title === artwork.title ? colours.tint : null
            }}
            background={{
              default: tint.fade(0.9).string(),
              hover: tint.fade(0.7).string(),
              active: tint.fade(0.95).string()
            }}
          >
            {artwork.title}
          </ArtListItem>
        ))}
      </ArtListStyled>
      <ExpandList
        onClick={() => setExpand(!expand)}
        background={tint
          .darken(0.9)
          .fade(0.6)
          .string()}
        colour={colours.tint}
        showExpand={showExpand}
        height={height}
      >
        <FontAwesomeIcon icon={expand ? faChevronUp : faChevronDown} />
      </ExpandList>
    </ListWrapper>
  );
};
