import React, { createContext, useState, useEffect } from 'react';
import { firestore } from '../firebase';

export const SettingsContext = createContext({});

export const SettingsProvider = ({ children }) => {
  const [state, setState] = useState({ bio: '' });

  const changeSettings = settings => {
    setState(s => ({ ...s, ...settings }));

    firestore
      .doc('settings/bio')
      .update(settings)
      .catch(err => console.log(err.message));
  };

  useEffect(() => {
    firestore
      .doc('settings/bio')
      .get()
      .then(res => setState(res.data()));

    return () => {
      console.log('got the settings');
    };
  }, []);

  return (
    <SettingsContext.Provider value={{ ...state, changeSettings }}>
      {children}
    </SettingsContext.Provider>
  );
};
