import React, { useContext } from 'react';
import styled from 'styled-components';

import { useMediaQuery } from '@material-ui/core';

import bgImage from '../../assets/bg.png';
import { BeugThemeContext } from '../../context/ThemeContext';

export const AdminContent = ({ children }) => {
  const { breakpoints } = useContext(BeugThemeContext);
  const isMobile = !useMediaQuery(`(min-width: ${breakpoints.md})`);

  const Content = styled.div`
  margin-left: auto;
  display: flex;
  width: ${isMobile ? '100%' : 'calc(100% - 280px)'};
  flex-direction: column;
  justify-content: flex-start;
  padding: 40px;
  overflow-y: scroll;
  &:after {
    content: '';
    background-image: url('${bgImage}');
    background-size: 20px;
    opacity: 0.1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
`;

  return <Content>{children}</Content>;
};
