import React, { useContext } from 'react';
import styled from 'styled-components';
import Color from 'color';
import { BeugThemeContext } from '../../../context/ThemeContext';

const EditForm = styled.form`
  padding: 2rem;
  width: 100%;
  height: 100%;
  background: ${props => props.background};
  display: flex;
  flex-direction: column;
`;

export default ({ handleSubmit, children }) => {
  const { colours } = useContext(BeugThemeContext);

  let truBlack = Color(colours.truBlack);

  return (
    <EditForm
      onSubmit={handleSubmit}
      background={truBlack.fade(0.05).toString()}
    >
      {children}
    </EditForm>
  );
};
