import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUpload, faTimes } from '@fortawesome/pro-light-svg-icons';

import {
  TextField,
  Button,
  FormControl,
  Grid,
  Box,
  FormControlLabel,
  Checkbox,
  LinearProgress
} from '@material-ui/core';

import { BeugThemeContext } from '../../../context/ThemeContext';
import { storage, firestore } from '../../../firebase';

const Form = styled.form`
  margin: auto;
  display: flex;
  padding: 4rem;
  max-width: 720px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${props => props.background};
  border: 1px solid;
`;

const ImageBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
`;

const PhotoPreview = styled.div`
  position: relative;
  img {
    width: 100%;
  }
`;

const DeleteIcon = styled.div`
  cursor: pointer;
  position: absolute;
  top: -1rem;
  right: -1rem;
  padding: 1rem 1.5rem;
  background: ${props => props.background};
  border: 2px solid ${props => props.colour};
  &:hover {
    color: ${props => props.colour};
  }
  &:active {
    background: ${props => props.colour};
    border-color: ${props => props.background};
    color: ${props => props.background};
  }
`;

const Message = styled.div`
  padding: 2rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  color: ${props => props.colour};
`;

const INITIAL_STATE = {
  title: '',
  year: '',
  price: '',
  digital: false,
  dimensions: {
    width: '0',
    height: '0'
  },
  etsy: '',
  societySix: '',
  imageUrl: ''
};

export const UploadArtwork = () => {
  const { colours } = useContext(BeugThemeContext);
  const [uploading, setUploading] = useState(null);
  const [message, setMessage] = useState({ error: false, text: '' });
  const [state, setState] = useState(INITIAL_STATE);

  const [photo, setPhoto] = useState({});

  const handleChange = e => {
    const { name, value } = e.target;
    setState(s => ({ ...s, [name]: value }));
  };

  const handleDimensionChange = e => {
    const { name, value } = e.target;
    setState(s => ({ ...s, dimensions: { ...s.dimensions, [name]: value } }));
  };

  const handleDigiCheck = e => {
    setState(s => ({ ...s, digital: !s.digital }));
  };

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  const handleFile = async e => {
    const file = e.target.files[0];
    setPhoto({ name: file.name, file });
    await toBase64(file).then(data => {
      setPhoto(s => ({ ...s, data }));
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const { title, year, price, dimensions, digital, etsy, societySix } = state;

    setUploading(true);

    if (!photo.data) {
      setUploading(false);
      setMessage({ error: true, text: 'Please select an image' });
      return;
    }

    const uploadTask = storage
      .ref()
      .child(`images/${photo.name}`)
      .put(photo.file);

    uploadTask.on(
      'state_changed',
      snapshot => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploading(progress);
      },
      err => setMessage({ error: true, text: err.message }),
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then(imageUrl => {
          firestore
            .collection('artwork')
            .doc()
            .set({
              title,
              year,
              price,
              digital,
              dimensions,
              etsy,
              societySix,
              imageUrl
            })
            .then(() => {
              setMessage({
                error: false,
                text: 'Artwork submitted'
              });
              setUploading(null);
              setPhoto({});
              setState(INITIAL_STATE);
            })
            .catch(err => setMessage({ error: true, text: err.message }));
        });
      }
    );
  };

  return (
    <Form onSubmit={handleSubmit} background={colours.black}>
      <ImageBox>
        {photo.data ? (
          <PhotoPreview>
            <DeleteIcon
              onClick={() => setPhoto({})}
              background={colours.black}
              colour={colours.red}
            >
              <FontAwesomeIcon icon={faTimes} />
            </DeleteIcon>
            <img src={photo.data} alt={photo.name} />
          </PhotoPreview>
        ) : (
          <>
            <input
              accept='image/*'
              style={{ display: 'none' }}
              id='upload-button'
              type='file'
              onChange={handleFile}
            />
            <label htmlFor='upload-button'>
              <Button
                variant='outlined'
                component='span'
                startIcon={
                  !photo.name && <FontAwesomeIcon icon={faCloudUpload} />
                }
              >
                {!photo.name ? 'Choose Image' : photo.name}
              </Button>
            </label>
          </>
        )}
      </ImageBox>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <TextField
              name='title'
              value={state.title}
              onChange={handleChange}
              label='Title'
              color='secondary'
              variant='outlined'
              fullWidth
              required
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              name='year'
              value={state.year}
              onChange={handleChange}
              label='Year'
              color='secondary'
              variant='outlined'
              fullWidth
              required
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              name='price'
              value={state.price}
              onChange={handleChange}
              label='Price'
              color='secondary'
              variant='outlined'
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} md={3}>
          <FormControl fullWidth>
            <TextField
              disabled={state.digital}
              name='width'
              value={state.dimensions.width}
              onChange={handleDimensionChange}
              label='Width'
              color='secondary'
              variant='outlined'
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} md={3}>
          <FormControl fullWidth>
            <TextField
              disabled={state.digital}
              name='height'
              value={state.dimensions.height}
              onChange={handleDimensionChange}
              label='Height'
              color='secondary'
              variant='outlined'
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <TextField
              name='etsy'
              value={state.etsy}
              onChange={handleChange}
              label='Etsy Link'
              color='secondary'
              variant='outlined'
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <TextField
              name='societySix'
              value={state.societySix}
              onChange={handleChange}
              label='Society6 Link'
              color='secondary'
              variant='outlined'
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={state.digital}
                value={state.digital}
                onChange={handleDigiCheck}
              />
            }
            label='Digital'
            style={{
              textTransform: 'uppercase',
              fontWeight: '700'
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center', marginTop: '1rem' }}>
          {uploading ? (
            <LinearProgress
              variant='determinate'
              value={uploading}
              color='secondary'
            />
          ) : (
            <Button color='secondary' variant='contained' type='submit'>
              Upload
            </Button>
          )}
        </Grid>
      </Grid>
      {message.text && (
        <Message colour={message.error ? colours.red : colours.green}>
          {message.text}
        </Message>
      )}
    </Form>
  );
};
