import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

import { BeugThemeContext } from '../../context/ThemeContext';
import { SettingsContext } from '../../context/SettingsContext';
import { Button } from '../ui/forms/Button';

const Container = styled.form`
  max-width: 280px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  padding: 0 3rem;
`;

const Label = styled.label`
  background: ${props => props.background};
  color: white;
  border: 1px solid;
  border-bottom: none;
  padding: 10px;
  text-transform: uppercase;
  font-weight: 700;
`;

const TextArea = styled.textarea`
  font-size: 2rem;
  padding: 0 20px 20px;
  background: ${props => props.background};
  color: white;
  border: 1px solid;
  border-top: none;
  outline: 0;
  box-shadow: none;
  min-height: 300px;
`;

export const ChangeBio = () => {
  const [bio, setBio] = useState({ bio: '' });
  const [state, setState] = useState({ loading: false, saved: false });
  const { colours } = useContext(BeugThemeContext);
  const settings = useContext(SettingsContext);

  const handleChange = e => {
    const { value } = e.target;
    setBio(s => ({ ...s, bio: value }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setState({ loading: true, saved: false });
    await settings.changeSettings(bio);
    setState({ loading: false, saved: true });
  };

  useEffect(() => {
    setBio({ bio: settings.bio });
    if (state.saved) {
      setTimeout(() => setState({ loading: false, saved: false }), 1000);
    }
  }, [settings.bio, state.saved]);

  return (
    <Container onSubmit={handleSubmit}>
      <Label htmlFor='bio' background={colours.truBlack}>
        Change Bio
      </Label>
      <TextArea
        name='bio'
        value={bio.bio}
        onChange={handleChange}
        background={colours.truBlack}
      />

      <Button
        disabled={state.saved}
        colour={colours.tint}
        style={{ alignSelf: 'center', marginTop: '1rem' }}
      >
        {state.loading ? <CircularProgress color='secondary' /> : 'Save'}
      </Button>
    </Container>
  );
};
