import React, { useContext } from 'react';
import styled from 'styled-components';

import { MenuItem } from './MenuItem';
import { Society6 } from './svgs/Society6';
import { Etsy } from './svgs/Etsy';
import { MetaIV } from './svgs/MetaIV';
import { Volim } from './svgs/Volim';
import { BeugThemeContext } from '../../context/ThemeContext';

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  margin-bottom: 1rem;
  text-align: left;
`;

const EtsyStyled = styled(Etsy)`
  ${props => props.style}
`;
const Society6Styled = styled(Society6)`
  ${props => props.style}
`;
const MetaIVStyled = styled(MetaIV)`
  ${props => props.style}
`;
const VolimStyled = styled(Volim)`
  ${props => props.style}
`;

export const Navigation = () => {
  const { colours } = useContext(BeugThemeContext);
  const logoStyles = `height: 2.4rem; fill: ${colours.white}`;

  return (
    <Nav>
      <div style={{ width: '100%' }}>
        <div>
          <MenuItem
            label='Etsy'
            link='https://www.etsy.com/au/shop/Beug'
            isExternal
            isSvg
            svg={<EtsyStyled style={logoStyles} />}
          />
          <MenuItem
            label='Society 6'
            link='https://society6.com/beug'
            isExternal
            isSvg
            svg={<Society6Styled style={logoStyles} />}
          />
        </div>
        <div>
          <MenuItem
            label='META IV'
            link='https://metaiv.media'
            isExternal
            isSvg
            svg={<MetaIVStyled style={logoStyles} />}
          />
          <MenuItem
            label='Volim'
            link='http://volim.com.au'
            isExternal
            isSvg
            svg={<VolimStyled style={logoStyles} />}
          />
        </div>
      </div>
    </Nav>
  );
};
