import React, { useContext } from 'react';
import styled from 'styled-components';
import Color from 'color';

import { useMediaQuery } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft
} from '@fortawesome/pro-light-svg-icons';

import { BeugThemeContext } from '../../context/ThemeContext';
import { ArtContext } from '../../context/ArtContext';

const ControlsBox = styled.div`
  ${props => props.mobileStyles}
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: ${props => props.background};
  border: 1px solid;
  border-top: none;
`;

const Navigator = styled.div`
  width: 50%;
  text-align: center;
  padding: ${props => (props.isMobile ? '18px 0' : '0.5rem 0')};
  background: ${props => props.background.normal};
  &:hover {
    background: ${props =>
      props.isMobile
        ? props => props.background.normal
        : props => props.background.hover};
  }
  &:active {
    background: ${props =>
      props.isMobile
        ? props => props.background.normal
        : props => props.background.hover};
  }
`;

export const Controls = () => {
  const { colours, breakpoints } = useContext(BeugThemeContext);
  const isMobile = !useMediaQuery(`(min-width: ${breakpoints.md})`);
  const { art, allArt, selectArt } = useContext(ArtContext);
  const tint = Color(colours.tint);

  const nextArt = () => {
    allArt.forEach((artItem, index) => {
      if (art.title === artItem.title) {
        const next = index === allArt.length - 1 ? 0 : index + 1;
        selectArt(allArt[next]);
      }
    });
  };

  const prevArt = () => {
    allArt.forEach((artItem, index) => {
      if (art.title === artItem.title) {
        const prev = index === 0 ? allArt.length - 1 : index - 1;
        selectArt(allArt[prev]);
      }
    });
  };

  return (
    <ControlsBox
      background={colours.truBlack}
      mobileStyles={!isMobile && `position: absolute; bottom: -36px;  left: 0;`}
    >
      <Navigator
        onClick={prevArt}
        isMobile={isMobile}
        background={{
          default: tint.fade(0.9).string(),
          hover: tint.fade(0.7).string(),
          active: tint.fade(0.95).string()
        }}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </Navigator>
      <Navigator
        onClick={nextArt}
        isMobile={isMobile}
        background={{
          default: tint.fade(0.9).string(),
          hover: tint.fade(0.7).string(),
          active: tint.fade(0.95).string()
        }}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </Navigator>
    </ControlsBox>
  );
};
