import React, { useContext } from 'react';
import styled from 'styled-components';
import Color from 'color';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-light-svg-icons';
import { BeugThemeContext } from '../../context/ThemeContext';

export const MenuItem = ({
  link,
  icon,
  label,
  isSvg,
  svg,
  isExternal,
  active
}) => {
  const { colours } = useContext(BeugThemeContext);

  let black = Color(colours.black);

  const MenuItemStyled = styled.a`
    border-top: 1px solid ${black.lighten(0.2).toString()};
    display: flex;
    width: 100%;
    max-height: 6rem;
    padding: 2rem;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 2rem;
    font-weight: 700;
    &:last-child {
      border-bottom: 1px solid ${black.lighten(0.2).toString()};
    }
    &:hover {
      background: ${black.lighten(0.2).toString()};
      .fa-external-link {
        opacity: 1;
      }
    }
    &:active {
      background: ${black.darken(0.6).toString()};
    }
    .fa-external-link {
      transition: all ease 140ms;
      opacity: 0;
    }
  `;
  const MenuLink = styled(Link)`
    border-top: 1px solid ${black.lighten(0.2).toString()};
    display: flex;
    width: 100%;
    max-height: 6rem;
    padding: 2rem;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 2rem;
    font-weight: 700;
    &:last-child {
      border-bottom: 1px solid ${black.lighten(0.2).toString()};
    }
    &:hover {
      background: ${black.lighten(0.2).toString()};
      .fa-external-link {
        visibility: visible;
      }
    }
    &:active {
      background: ${black.darken(0.6).toString()};
    }
    .fa-external-link {
      visibility: hidden;
    }
  `;
  return isSvg ? (
    <MenuItemStyled href={link} rel='noopener noreferrer' target='_blank'>
      {svg}
      {isExternal && (
        <FontAwesomeIcon icon={faExternalLink} style={{ marginLeft: 'auto' }} />
      )}
    </MenuItemStyled>
  ) : isExternal ? (
    <MenuItemStyled
      href={link}
      className={active && 'text-tint'}
      rel='noopener noreferrer'
      target='_blank'
    >
      {icon && <FontAwesomeIcon icon={icon} />}
      {label}
    </MenuItemStyled>
  ) : (
    <MenuLink to={link}>
      {icon && <FontAwesomeIcon icon={icon} style={{ marginRight: '1rem' }} />}
      {label}
    </MenuLink>
  );
};
