import React from 'react';
import styled from 'styled-components';
import { IconButton, Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/pro-solid-svg-icons';

const FilterStyled = styled.div`
  display: flex;
  margin-bottom: 3rem;
`;

export const Filter = ({ sortFunction, filter }) => {
  return (
    <FilterStyled>
      {filter && (
        <>
          <IconButton
            onClick={() =>
              sortFunction(
                filter.field,
                filter.direction === 'asc' ? 'desc' : 'asc'
              )
            }
            style={{ marginRight: '1.5rem' }}
          >
            <FontAwesomeIcon
              icon={filter.direction === 'asc' ? faArrowUp : faArrowDown}
            />
          </IconButton>
          <Button
            color={filter.field === 'title' ? 'secondary' : 'default'}
            onClick={() => sortFunction('title', filter.direction)}
          >
            Name
          </Button>
          <Button
            color={filter.field === 'year' ? 'secondary' : 'default'}
            onClick={() => sortFunction('year', filter.direction)}
          >
            Year
          </Button>
        </>
      )}
    </FilterStyled>
  );
};
