import React, { useContext } from 'react';
import styled from 'styled-components';

import { faUpload, faEye } from '@fortawesome/pro-light-svg-icons';

import { Logo } from '../../assets/Logo';
import { MenuItem } from '../navigation/MenuItem';
import { BeugThemeContext } from '../../context/ThemeContext';
import { SignInOutButton } from '../footer/FooterButton';

import { ColourPicker } from './ColourPicker';
import { ChangeBio } from './ChangeBio';

const Nav = styled.nav`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
  justify-self: stretch;
  text-align: left;
`;

const Admin = styled.h1`
  color: ${props => props.colour};
  font-weight: 800;
  text-align: center;
  width: 100%;
  height: 70px;
  padding: 2rem 0;
  margin-top: -30px;
  border-bottom: 1px solid;
`;

export const AdminSidebar = () => {
  const { colours } = useContext(BeugThemeContext);

  return (
    <>
      <Logo height={'30px'} styles={`height: 30px; margin: 40px 0;`} />
      <Admin colour={colours.red}>ADMIN PANEL</Admin>
      <Nav>
        <MenuItem label='View All' link='/admin' icon={faEye} />
        <MenuItem label='Upload' link='/admin/create' icon={faUpload} />
      </Nav>
      <ColourPicker />
      <ChangeBio />
      <SignInOutButton />
    </>
  );
};
