import React, { useContext } from 'react';
import styled from 'styled-components';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import { BeugThemeContext } from '../../../context/ThemeContext';

export const Popup = ({ open, title, content, onSubmit, onClose }) => {
  const { colours, typography } = useContext(BeugThemeContext);

  const DialogStyled = styled(Dialog)`
    .MuiDialog-paper {
      font-family: ${typography.fontFamilies};
      padding: 3rem;
      border: 1px solid ${colours.red};
      background: ${colours.truBlack};
    }
  `;

  return (
    <DialogStyled open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onSubmit}>Ok</Button>
      </DialogActions>
    </DialogStyled>
  );
};
