import React, { useContext } from 'react';
import styled from 'styled-components';

import { useMediaQuery } from '@material-ui/core';

import bgImage from '../assets/bg.png';
import { ArtContext } from '../context/ArtContext';
import { BeugThemeContext } from '../context/ThemeContext';
import { Artwork } from './portfolio/Artwork';

const ContextBox = styled.div`
  width: ${props => (props.isMobile ? '100%' : 'calc(100% - 280px)')};
  margin-left: ${props => (props.isMobile ? '0' : 'auto')};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  &:after {
    content: '';
    background-image: url('${props => props.background}');
    background-size: 20px;
    opacity: 0.1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
`;

export const Content = () => {
  const { art } = useContext(ArtContext);
  const { breakpoints } = useContext(BeugThemeContext);
  const isMobile = !useMediaQuery(`(min-width: ${breakpoints.md})`);

  return (
    <ContextBox isMobile={isMobile} background={bgImage}>
      {art && <Artwork />}
    </ContextBox>
  );
};
