import React, { useContext } from 'react';
import styled from 'styled-components';
import Color from 'color';
import { BeugThemeContext } from '../../../context/ThemeContext';

const ButtonStyled = styled.button`
  border: ${props => props.border};
  background: ${props => props.background.default};
  color: ${props => props.colour.default};
  font-size: 1.8rem;
  font-weight: 800;
  text-transform: uppercase;
  padding: 0.8rem 1.5rem;
  margin-bottom: 2rem;
  margin: ${props => props.margin};
  cursor: pointer;
  &:hover {
    background: ${props => props.background.hover};
    color: ${props => props.colour.hover};
  }
  &:active {
    background: ${props => props.background.active};
    color: ${props => props.colour.active};
  }
`;

export const Button = ({
  colour,
  solid,
  onClick,
  noSubmit,
  children,
  margin,
  disabled,
  style
}) => {
  const { colours } = useContext(BeugThemeContext);
  let tint = Color(colour);
  let black = Color(colours.black);
  return (
    <ButtonStyled
      type={noSubmit ? 'button' : 'submit'}
      onClick={onClick}
      disabled={disabled}
      style={style}
      border={solid ? 'none' : '1px solid'}
      margin={margin}
      background={{
        default: solid ? colour : 'none',
        hover: solid ? tint.lighten(0.1).string() : tint.fade(0.95).toString(),
        active: solid ? colour : tint.fade(0.75).toString()
      }}
      colour={{
        default: solid
          ? colours.black
          : disabled
          ? black.lighten(0.4).string()
          : colour,
        hover: solid
          ? colours.black
          : disabled
          ? black.lighten(0.4).string()
          : tint.lighten(0.1).string(),
        active: solid ? colours.black : colour
      }}
    >
      {children}
    </ButtonStyled>
  );
};
