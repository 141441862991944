import React, { useContext } from 'react';

import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core';
import { BeugThemeContext } from './ThemeContext';

export const MaterialThemeProvider = ({ children }) => {
  const theme = useContext(BeugThemeContext);
  const materialTheme = createMuiTheme({
    palette: {
      type: 'dark',
      primary: {
        main: theme.colours.black
      },
      secondary: {
        main: theme.colours.tint
      }
    },
    typography: {
      fontFamily: theme.typography.fontFamilies,
      fontSize: 24,
      fontWeightRegular: 400,
      fontWeightMedium: 700,
      fontWeightBold: 800
    },
    shape: {
      borderRadius: 0
    }
  });

  return <ThemeProvider theme={materialTheme}>{children}</ThemeProvider>;
};
